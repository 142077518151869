import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/certificate-center/certificates', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/certificate-center/certificate/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;

    return Api.post('/certificate-center/certificate', data);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/certificate-center/certificate/' + id, formData);
}

const signerUpdate = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/certificate-center/certificate/signers/' + id, formData);
}

const otherSignerUpdate = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/certificate-center/certificate/other-signers/' + id, formData);
}

const addLogo = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/certificate-center/certificate/add-logo/' + id,formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const downloadLogo = async (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/certificate-center/certificate/download-logo/' + uuid, {responseType: 'arraybuffer'});
}

const deleteLogo = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/certificate-center/certificate/delete-logo/' + id, formData);
}

const deleteCertificate = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/certificate-center/certificate/' + id);
}

export default {
    getAll,
    get,
    update,
    signerUpdate,
    otherSignerUpdate,
    addLogo,
    store,
    downloadLogo,
    deleteLogo,
    deleteCertificate
}
